@import 'bootstrap/dist/css/bootstrap.min.css';
@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
}

*{
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  min-width: 100vw;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root .App {
  min-width: 100vw;
  min-height: 100vh;
}

.App {
	text-align: center;
}


.al-center {
  align-items: center;
  display: flex;
}

/* width */
::-webkit-scrollbar {
  width: 0.35em;
  height: 0.35em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fffefedc; 
  border-radius: 5px 10px 10px 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #6e6e6e; 
  border-radius: 5px 10px 10px 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #252525; 
}

h1 {
  color: whitesmoke;
  z-index: 10;
  padding: 0;
  font-size: 1.5rem;
  text-shadow: 0.1rem 0.07rem black;
  margin-top: 0;
  margin-bottom: 1rem;
}

#rbtn {
  width: fit-content;
  margin: 0 auto 1rem auto;
  color: whitesmoke;
}

#rbtn label {
  background-color: rgba(103, 124, 118, 0.52);
  display: inline-flex;
  justify-content: center;
  padding: 0.3rem;
  border: 1px solid black; 
  border-radius: 10px;
  width: auto;
}

#rb1+label[for="rb1"]{
  margin-right: 1rem;
}


#rbtn input[type="radio"]:checked+label{ 
  font-weight: bold;
  font-style: italic;
}

#rbtn > input {
  opacity: 0; 
  width: 0; 
}