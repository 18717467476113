.back-profile-page {
  background-image: url("/public/img/profile-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  height: auto;
}

.back-profile-page > div {
	backdrop-filter: blur(10px);
	align-items: center;
	display: flex;
	width: 100vw;
	min-height: 100vh;
	height: auto;
	flex-direction: column;
	overflow-y: hiden;
}

.profile-page {
  min-height: 100vh;
  height: auto;
}

.profile-page > h1 {
	margin-top: 3rem;
}

.profile-details {
  background: linear-gradient(
      180deg,
      rgba(83, 83, 83, 0.16) 0%,
      rgba(96, 96, 96, 0.4) 100%
    ),
    linear-gradient(
      90.24deg,
      rgba(113, 113, 113, 0.443) 10.75%,
      rgba(102, 107, 110, 0.517) 29.39%,
      rgba(125, 142, 137, 0.582) 49.19%,
      rgba(121, 120, 120, 0.562) 71.57%,
      rgba(79, 79, 79, 0.588) 94.38%
    );
  border: 1px solid;
  border-image: linear-gradient(
    90.24deg,
    rgba(255, 255, 255, 0.664) 11.75%,
    rgba(71, 85, 93, 0.28) 29.39%,
    rgba(76, 90, 86, 0.52) 49.19%,
    rgba(123, 136, 132, 0.67) 71.57%,
    rgba(123, 134, 115, 0.87) 94.38%
  ) 1;
  border-radius: 10px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.profile-details > div {
  display: flex;
  flex-direction: column;
  align-items: center; 
  position: relative;
}

.edit-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
}
.edit-btn:hover {
  cursor: pointer;
}

.profile-picture {
  margin: 1rem 0 1rem 0;
  border-radius: 50%;
  min-width: 40%;
  aspect-ratio: 1 / 1;
  height: 100%;
  border: 8px solid rgba(255, 255, 255, 0.49);
}

.profile-details .nickname {
  font-size: 1.5rem;
  font-weight: bold;
}

.profile-details .prof {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.profile-details .personal-info {
  width: 50%;
  margin: 2rem 0 4rem 0;
  font-weight: bold;
}


.social-links {
  margin-bottom: 0.5rem;
}

.social-links ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.social-links > ul > li:first-child {
  margin-left: 0;
}

.social-links ul li {
  margin-left: 2rem;
  padding: 0.2rem;
}

.social-links img {
  width: 2rem;
  aspect-ratio: 1 / 1;
  height: 100%;
}

.social-links ul li:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition-duration: 1.5s;
}

.profile-details h1 {
  font-size: medium;
}